import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import ModalBox from "components/Modal/ModalBox";
import CustomFilter from "components/Reports/CustomFilter";
import JobDetailsModalContent from "components/JobDetailsModalContent/JobDetailsModalContent";
import { FilterContext } from "context/FilterContext";

import { UserContext } from "context/UserContext";
import { maximumExportPaging, PAGES } from "helpers/Constant";
import {
  convertGridColumnMenu,
  copyToClipboard,
  delay,
  getColumnsAfterChangeOrder,
  getCurrentPageAllFiltersFromFilterStore,
  formatDate
} from "helpers/ReusableFunctions";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import MatchModalContent from "components/CandidateProfileModalContent/MatchModalContent";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getJobList } from "services/FetchApiData";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { uuidv4 } from "helpers/ReusableFunctions";
import StarOutlineSvg from "../../../assets/svg/star-outline-black.svg";

const CandidateJobList = ({
  data,
}) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const location = useLocation();

  const {
    filterStore,
    applyFilterFlags: { applyFilterInJobs },
    setLastAppliedFilterstore,
    setIsDisableApplyButton,
    isInternalUser,
    Clients,
  } = useContext(FilterContext);
  const { 
    language, 
    userGroup,
    loggedInUserData 
  } = useContext(UserContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "includes_retained",
      headerName: "",
      width: 50,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: ({ value }) => value ? (
        <Tooltip title={"Retained Search"} arrow>
          <img src={StarOutlineSvg} alt="star" style={{ width: "13px", height: "13px" }} />
        </Tooltip>
      ) : ""
    },
    ...(!location.pathname.includes("/card")
        ? [
            {
              field: "esai_score",
              headerName: "ESAI Score",
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
            {
              field: "esai_match",
              headerName: "ESAI Match",
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: (params) => {
                if (params?.value) {
                  return (
                    <Link onClick={(e) => handleShowMatchModal(e, params?.row)}>
                      View
                    </Link>
                  );
                }
                return "";
              },
            },
            {
              field: "first_targeted",
              headerName: "Date added",
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: ({ value }) => formatDate(value),
            },
            {
              field: "last_targeted",
              headerName: "Most Recent Contact",
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: ({ value }) => formatDate(value),
            },
          ]
        : []
      ),
    ...(isInternalUser
      ? [
          {
            field: "client_name",
            headerName: "Client Name",
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    {
      field: "position_name",
      headerName: t("jobs.jobNameEn"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "position_name_ja",
      headerName: t("jobs.jobNameJa"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "role",
      headerName: t("jobs.jobCategory"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "focus",
      headerName: t("jobs.speciality"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    ...(isInternalUser
      ? [
          {
            field: "industry",
            headerName: t("filterNav.industry"),
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
          {
            field: "company_stage",
            headerName: "Company Stage",
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    {
      field: "remote_policy",
      headerName: t("jobs.remoteStatus"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "location",
      headerName: t("jobs.location"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "position_id",
      headerName: "Job ID",
      width: 280,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: ({ value }) => (
        <div
          className="MuiDataGrid-cellContent cursor-pointer text-decoration-underline"
          title="Click to Copy"
          onClick={(e) => handleCopyJobId(e, value)}
        >
          {value}
        </div>
      ),
    },
  ];

  const isJobsFetch = useRef(false);
  const [jobListData, setJobListData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 1000, offset: 0 });
  const [showExportModal, setShowExportModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [hiddenColumns, setHiddenColumns] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    setInitialRenderFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      setColumnsOrder((prev) => {
        const temp = prev.map((item) =>
          getColumnsAfterChangeOrder(item, columns)
        );
        return temp;
      });
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (!initialRenderFlag) {
      if (pagination?.offset === 0) {
        fetchJobListData();
      } else {
        apiRef.current.setPage(0);
      }
    }
    return () => setJobListData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyFilterInJobs,
    initialRenderFlag,
    language,
    filterStore.startDate,
    filterStore.endDate,
    data.outreach_id
  ]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleCopyJobId = (e, value) => {
    e.stopPropagation();
    copyToClipboard(value);
    handleOpenSnackbar();
  };

  const fetchJobListData = async (paginationValues = pagination, outreachIdValue = data.outreach_id) => {
    if (isLoadingTableData || isJobsFetch.current) return;
    try {
      isJobsFetch.current = true;
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getJobList(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.JOBS
        ),
        Clients,
        language,
        paginationValues,
        outreachIdValue
      );
      setJobListData([
        ...(paginationValues?.offset === 0 ? [] : jobListData),
        ...(response?.data?.data?.positions?.client_jobs?.data?.map((el) => ({
          ...el,
          id: data.id || uuidv4(),
          first_name: data?.first_name,
          last_name: data?.last_name,
          kanji_first: data?.kanji_first,
          kanji_last: data?.kanji_last,
          age: data?.age,
          current_title: data?.current_title,
          current_employer: data?.current_employer,
          salary: data?.salary,
          salary_breakdown: data?.salary_breakdown,
        }))),
      ]);
      setPagination({
        ...paginationValues,
        count: response?.data?.data?.positions?.client_jobs?.count || 0,
        hasMore: response?.data?.data?.positions?.client_jobs?.has_more,
      });
      setLastAppliedFilterstore(filterStore);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
      isJobsFetch.current = false;
    }
  };

  const handleShowDetailsModal = () => {
    setShowDetailsModal((current) => !current);
  };

  const handlePaginationModelChange = async ({ pageSize, page }) => {
    let isPageSizeChanged = pagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...pagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    apiRef.current.setPage(newPage);
    setPagination(newPaging);
    await fetchJobListData(newPaging, data.outreach_id);
  };

  const handleExport = async () => {
    await handlePaginationModelChange(maximumExportPaging);
    await apiRef.current.setPaginationModel(maximumExportPaging);
    await delay(1000);
  };

  const handleShowMatchModal = (e, data) => {
    e.stopPropagation();
    setSelectedRow(data);
    setShowMatchModal(true);
  }

  const handleCloseMatchModal = () => {
    setShowMatchModal((current) => !current);
  }

  return (
    <>
      <div className="container job-details-card">
        <div className="cstm-mui-datagrid">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">{""}</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              {(userGroup.includes("external") ||
                (userGroup.includes("internal") &&
                  loggedInUserData.is_admin)) && (
                <div
                  className="action-button align-self-center"
                  onClick={handleShowExportModal}
                >
                  <div className="m-0">{t("performanceReportText.export")}</div>
                </div>
              )}
            </div>
          </div>
          <DataGridPro
            loading={isLoadingTableData}
            rows={jobListData}
            rowCount={pagination?.count}
            pagination
            pageSizeOptions={[50, 100, 500, 1000]}
            onPaginationModelChange={handlePaginationModelChange}
            columns={columnsWithOperators}
            slotProps={{
              filterPanel: {
                sx: { maxWidth: "calc(90vw - 24px)" },
              },
              pagination: {
                backIconButtonProps: {
                  disabled: isLoadingTableData || !pagination?.offset,
                },
                nextIconButtonProps: {
                  disabled: isLoadingTableData || !pagination?.hasMore,
                },
              },
            }}
            getRowId={(row) => row.position_id}
            getRowClassName={({ row }) =>
              row?.active_searches > 0 ? "bg-lightGreen" : ""
            }
            onColumnOrderChange={(c) => {
              setColumnsOrder((prevCols) => {
                const newCols = [...prevCols];
                newCols.splice(c.oldIndex, 1);
                newCols.splice(c.targetIndex, 0, c.column);
                return newCols;
              });
            }}
            initialState={{
              ...columns.initialState,
              sorting: {
                sortModel: [{ field: "last_targeted", sort: "desc" }],
              },
              columns: columns.initialState?.columns,
              pagination: { paginationModel: { pageSize: 1000, page: 0 } },
            }}
            onRowClick={(params) => {
              setSelectedRow(params.row);
              handleShowDetailsModal();
            }}
            localeText={convertGridColumnMenu(language)}
            apiRef={apiRef}
            onColumnVisibilityModelChange={(hideColumns) => {
              const hideColsName = Object.entries(hideColumns)
                .filter(([_key, value]) => value === false)
                ?.map((col) => col[0]);
              setHiddenColumns(hideColsName);
            }}
            rowsLoadingMode={"server"}
          />
        </div>
      </div>
      <ModalBox
        show={showDetailsModal}
        onHide={handleShowDetailsModal}
        content={
          apiRef?.current?.state && (
            <JobDetailsModalContent
              title={t("jobs.jobDetailsView")}
              closeModal={handleShowDetailsModal}
              current={selectedRow}
              apiRef={apiRef}
            />
          )
        }
      />
      <ModalBox
        show={showMatchModal}
        onHide={handleCloseMatchModal}
        content={
          apiRef?.current?.state && (
            <MatchModalContent
              isCandidatesAccess
              closeModal={handleCloseMatchModal}
              candidateSelected={data}
              jobInfo={selectedRow}
              title="Candidate Match Details"
              apiRef={apiRef}
            />
          )
        }
      />
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            fileName="Targeted Jobs"
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            apiRef={apiRef}
            // hiddenColumns={hiddenColumns}
            onExport={handleExport}
          />
        }
      />
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleOpenSnackbar}
        message="Job ID Copied"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleOpenSnackbar}
          >
            <IoIosClose />
          </IconButton>
        }
      />
    </>
  );
};

export default CandidateJobList;
